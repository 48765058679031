import services from 'api/services';

const formatResponseError = (request, response, data, startTime, endTime) => {
    return {
        ...data,
        callDurationTimes: {
            endTime,
            startTime
        },
        headers: response.headers,
        request: request,
        status: response.status,
        statusText: response.statusText,
        url: response.url
    };
};

const getHeaders = headerProps => {
    return {
        'content-type': headerProps.contentType,
        'x-api-key': headerProps.apiKey,
        'x-app-id': headerProps.appId,
        'x-user-experience-id': headerProps.uuid
    };
};

const getRequestBody = (body, requestProps) => {
    let requestBody = body;

    if (requestProps) {
        requestBody = {
            ...requestBody,
            client_id: requestProps.clientId ? requestProps.clientId : null,
            response_type: requestProps.response_type ? requestProps.response_type : null,
            scope: requestProps.scope ? requestProps.scope : null
        };
    }

    return requestBody;
};

const getRequestOptions = (body, options) => {
    let requestBody = getRequestBody(body, options.requestProps);

    if (options.headerProps.contentType === 'application/x-www-form-urlencoded') {
        requestBody = Object.keys(requestBody)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestBody[key]))
            .join('&');
    } else {
        requestBody = JSON.stringify(requestBody);
    }

    return {
        body: requestBody,
        headers: { ...getHeaders(options.headerProps) },
        method: 'POST'
    };
};

export const post = async (name, body, options) => {
    const requestOptions = { ...getRequestOptions(body, options) };
    const startTime = new Date().getTime();
    const url = services[name] || '/';

    return fetch(url, requestOptions).then(async response => {
        let data = response.status === 204 ? '' : await response.json();

        if (!response.ok) {
            const endTime = new Date().getTime();

            throw {
                response: formatResponseError(requestOptions, response, data, startTime, endTime)
            };
        }

        return data;
    });
};
